
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import CoachNavigationVue from "@/components/coach/navigation.vue";
import CoachPlayersProfileNavigationVue from "@/components/coach/players/profile/navigation.vue";
import AppCoachPlayersProfileStatisticsMatchesIndexVue from "@/views/app/coach/players/profile/statistics/matches/index.vue";
import AppCoachPlayersProfileStatisticsTrainingsIndexVue from "@/views/app/coach/players/profile/statistics/trainings/index.vue";
import CoachPlayersProfileRootUserInformationIndexVue from "@/views/app/coach/players/profile/root/user-information/index.vue";

@Options({
  components: {
    ElRow,
    ElCol,
    CoachNavigationVue,
    CoachPlayersProfileNavigationVue,
    CoachPlayersProfileRootUserInformationIndexVue,
    AppCoachPlayersProfileStatisticsMatchesIndexVue,
    AppCoachPlayersProfileStatisticsTrainingsIndexVue,
  },
})
export default class CoachPlayersProfileStatisticIndexVue extends Vue {
}
